html {
    position: fixed;
    padding-bottom: 50px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-color: grey;
}

body {
    margin: 0;
    overflow: hidden;
    font-family: Helvetica, Arial, sans-serif;
}

p {
    line-height: 1.5rem;
}

li {
    line-height: 1.5rem;
}

.disabled {
    opacity: 0.5;
}

.watermark {
    position: fixed;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    background-image: url("../public/image/watermark.png");
    background-repeat: space;
    opacity: 0.1;
    z-index: -1;
    transform: rotate(-20deg);
    pointer-events: none;
}

.container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background-color: green;
    
    max-height: 100vh;
    overflow-y: scroll;

    .content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    
        text-align: center;
        height: calc(100vh - 6rem);
        margin-top: 4rem;
        padding: 1rem 1rem 0 1rem;

        &.zoom-in {
            height: auto;
            width: 90vw;
        }

        img {
            cursor: zoom-in;
            max-height: 100%;
            max-width: 100%;
        }

        // hacky fix for pdf delay
        & > div:nth-child(2) {
            display: none;
        }
    }
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: center;

    position: fixed;
    z-index: 999999;
    left: 0;
    padding: 0.5rem;
    height: 3rem;
    width: 100%;

    &.bottom {
        bottom: 0;
        background: linear-gradient(
            180deg,
            rgba(103, 103, 103, 1) 0%,
            rgba(24, 24, 24, 1) 100%
        );
    }

    &.top {
        height: 3rem;
        background: rgb(48, 46, 46);
        top: 0;
    }

    .left {
        margin-left: 2rem;
        position: absolute;
        display: inline-block;
        left: 0;
    }

    .middle {
        display: inline-block;
    }

    .right {
        margin-right: 2rem;
        position: absolute;
        display: inline-block;
        right: 0;
    }

    #title {
        color: white;
        font-size: 2rem;
        font-weight: 500;
    }
}

.url-container {
    margin: auto;
    min-width: 50vw;
    max-width: 80vw;
    padding: 0.5rem 0.5rem;
    overflow-wrap: break-word;
    line-height: initial;
    border-radius: 10px;
    background: #3d4241;

    .meta-container {
        max-height: 60vh;
        padding: 1rem;
        background: #2a2e2d;
        border-radius: 1rem;
        text-align: start;
        min-height: 5rem;
        overflow-y: scroll;

        .title {
            font-weight: bold;
            color: #dde0e0;
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        .description {
            color: #dde0e0;
            font-size: 1rem;
        }
    }

    .text-container {
        padding: 1% 0;
        display: flex;
        justify-content: center;
    }

    span {
        color: #dde0e0;
    }

    a {
        display: inline-block;
        color: #fabd2f;
    }
}

.video-container {
    position: relative;
    z-index: 0;
    margin-top: 5rem;
    width: 80vw;
    padding-bottom: calc(100vh - 6rem);
}

.video {
    position: absolute;
    top: -10%;
    left: 0;
    width: 100%;
    height: 100%;
}

.pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    overflow-x: scroll;
    overflow-y: scroll;
    width: auto;
    height: 100vh;
    padding: 1rem;
    // background-color: rgb(73, 40, 40);

    .placeholder {
        &.top {
            padding-top: 4rem;
        }

        &.bottom {
            padding-top: 3rem;
        }
    }

    // & > div {
    //     width: 50vw;
    // }
}

img.zoom-in {
    cursor: zoom-out !important;
    height: 100%;
    width: 100%;
}

